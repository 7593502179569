import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Container, Col } from "react-bootstrap"
import PressRoomMasthead from "./press-room-masthead"

const PressReleases = () => (
  <Layout hero={<PressRoomMasthead active="press-releases" />} showFeature>
    <SEO
      title="Press Releases | SpaceIt"
      description="Offical SpaceIt Press Releases"
    />
    <Container fluid className="px-0 main">
      <Container className="pt-3">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 className="my-3">Press Releases</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <ul className="list-group">
              <li className="mb-3">
                <div className="text-muted pb-2 press-release-date">
                  July 12, 2023
                </div>
                <a
                  className="pb-3"
                  href="spaceit-recognized-as-a-game-changer-for-real-estate-professionals"
                >
                  SpaceIt Recognized as a Game-Changer For Real Estate
                  Professionals
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default PressReleases
